<template>
  <v-parallax
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    height="600"
  >
    <v-layout align-center row justify-center>
      <v-flex xs12 sm12 md12>
        <h4 class="display-1 font-weight-bold mb-1">GoCheckIn</h4>
      </v-flex>
    </v-layout>
    <v-layout align-top row justify-center>
      <v-flex xs6 sm6 md6>
        <h1 class="heading">Login</h1>
        <v-dialog v-model="dialogDialpad" max-width="400px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" fab large dark v-on="on">
              <v-icon>dialpad</v-icon>
            </v-btn>
          </template>  

          <v-card color="rgba( 33,150,243 , 0.8 )">
            <v-card-title>
              <v-layout align-center row justify-center>
                <v-flex xs12 sm12 md12>
                  <v-icon dark>dialpad</v-icon>
                  <span class="headline" style="color:#FFFFFF;">Login</span>
                  <v-icon dark>dialpad</v-icon>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row>
                  <v-flex xs12 sm12 md12 d-flex>
                    <v-text-field type="number" label="Username" @focus="keyboardHide" prefix="+" v-model="username" color="white" dark></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex>
                    <v-text-field type="Password" label="Password" @focus="keyboardShow" v-model="password" color="white" dark></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout align-center row justify-center>
                  <v-flex>
                    <v-btn fab dark color="primary" v-if="!keyboard.visible" @click.native="dialpadLogin">
                      <v-icon large>touch_app</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex xs12 sm12 md12>
                    <vue-touch-keyboard v-if="keyboard.visible" :layout="keyboard.layout" :input="keyboard.input" :change="keyboardManualDispatch" :cancel="keyboardHide" :accept="keyboardAccept"/>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-flex>  
    </v-layout>
  </v-parallax>
</template>

<script>
import VueTouchKeyboard from 'vue-touch-keyboard'
import style from 'vue-touch-keyboard/dist/vue-touch-keyboard.css'
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog, Exceptions } from '@/utils'

// Vue.use(VueTouchKeyboard)

export default {
  data() {
    return {
      keyboard: {
        visible: false,
        layout: {
          _meta: {
            'backspace': { func: 'backspace', classes: 'control featured'},
            'accept': { func: 'accept', text: 'OK', classes: 'control featured'}
          },
          default: [
            "1 2 3",
            "4 5 6",
            "7 8 9",
            "0 {backspace} {accept}"
          ]
        },
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        },
      },


      dialogDialpad: false,
      dialogQrcode: false,
      qrPaused: false,
      username: null,
      password: null,
    }
  },
  // created () {
  //   // this.qrPaused = false
  // },
  // computed: {
  //   formTitle () {
  //     return 'Input to Login'
  //   },
  // },
  methods: {
    async barcodeDecoded(decodedString) {
      
      let loginParams
      
      try {
        if (decodedString) {
          this.qrPaused = true
          loginParams = JSON.parse(decodedString)
        } else {
          this.dialogQrcode = false
          this.qrPaused = false
          return
        }        
      } catch (e) {
        window.alert('Please use correct barcode.')

        this.dialogQrcode = false
        this.qrPaused = false

        return this.$router.go()  
      }

      try {
        await this.customLogin(loginParams)

        this.dialogQrcode = false
        this.qrPaused = false
      } catch (e) {
        console.error(e)

        window.alert(e.message)

        this.dialogQrcode = false
        this.qrPaused = false

        return this.$router.go()        
      }

    },

    async dialpadLogin() {
      
      const loginParams = {username: '+' + this.username, password: this.password}

      try {
        await this.customLogin(loginParams)

        this.dialogDialpad = false
      } catch (e) {
        console.error(e)

        window.alert(e.message)

        // this.dialogDialpad = false

        // return this.$router.go()        
      }

    },

    keyboardAccept(text) {
      console.log('Input text: ' + text)
      this.keyboardHide()
    },

    keyboardShow(e) {
      this.keyboard.input = e.target

      if (!this.keyboard.visible)
        this.keyboard.visible = true
    },

    keyboardHide() {
      this.keyboard.visible = false
    },
    keyboardManualDispatch () {
      this.keyboard.input.dispatchEvent(new Event('input', { bubbles: true }))
    },
    ...mapActions({
      customLogin: 'session/customLogin',
      logout: 'session/logout',
    })
  },
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component
  }
}
</script>

<style>
span.key {
  touch-action: manipulation;
}
</style>